<!-- Sources -->
<div class="form-group">
    <div>
        <div class="form-group">
            <div class="table-inputs">
                <div class="form-inline flex-grow-3">
                    <div class="form-group stay-inline">
                        <label class="form-check-label" for="target_bitrate"
                            >{{ "TARGET_BITRATE" | translate }}[{{ "KBPS" | translate }}]:<label
                                ><fa-icon class="ng-fa-icon" icon="asterisk" size="xs"></fa-icon></label
                        ></label>
                        <input
                            type="number"
                            [min]="0"
                            class="form-input ms-1 form-control form-control-sm form-control-xs"
                            id="target_bitrate"
                            name="target_bitrate"
                            [ngModel]="targetBitrate"
                            (ngModelChange)="targetBitrateChange.emit($event)"
                        />
                    </div>
                </div>
                <!-- <div class="form-check flex-grow-2 check-list">
                    <div>
                        <label class="form-check-label" for="auto_numbering">{{ "AUTO_NUMBERING" | translate }}</label>
                        <input type="checkbox" class="form-check-input" id="auto_numbering" name="auto_numbering" [(ngModel)]="autoNumbering" />
                    </div>
                    <div>
                        <label class="form-check-label" for="base_pid">{{ "BASE_PID" | translate }}</label>
                        <input type="checkbox" class="form-check-input" id="base_pid" name="base_pid" [(ngModel)]="basePid" />
                    </div>
                    <div>
                        <label class="form-check-label" for="increment">{{ "INCREMENT" | translate }}</label>
                        <input type="checkbox" class="form-check-input" id="increment" name="increment" [(ngModel)]="increment" />
                    </div>
                </div> -->
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-sm bordered m-0" [ngClass]="{ 'is-invalid': formSubmitted && selectedSources.length <= 0 }">
                <thead>
                    <tr>
                        <th scope="col" class="minw-200px">
                            {{ "SELECTED" | translate }}<label class="mb-0"><fa-icon icon="asterisk" size="xs"></fa-icon></label>
                        </th>
                        <th scope="col">{{ "PROGRAM_NAME" | translate }}</th>
                        <th scope="col">
                            {{ "PROGRAM" | translate }}<label class="mb-0"><fa-icon icon="asterisk" size="xs"></fa-icon></label>
                        </th>
                        <th scope="col">
                            {{ "BASE_PID" | translate }}<label class="mb-0"><fa-icon icon="asterisk" size="xs"></fa-icon></label>
                        </th>
                        <th scope="col"></th>
                    </tr>
                </thead>

                <tbody *ngIf="sources.length > 0">
                    <tr *ngFor="let mvSource of selectedSources">
                        <td class="maxw-160px ellipsis">
                            <zx-source
                                [model]="mvSource.source"
                                [showStatusIcon]="true"
                                [showStatusText]="false"
                                [showLink]="true"
                                [showTag]="false"
                                [searchTerm]="sourceFilter"
                            ></zx-source>
                        </td>
                        <td class="maxw-130px ellipsis">
                            <div class="form-inline">
                                <input
                                    type="text"
                                    name="programNameInput"
                                    class="form-control form-control-sm form-control-xs maxw-100px"
                                    [(ngModel)]="mvSource.program_name"
                                />
                            </div>
                        </td>
                        <td class="maxw-130px ellipsis">
                            <div class="form-inline">
                                <input
                                    type="number"
                                    [min]="1"
                                    [max]="65535"
                                    name="programInput"
                                    class="form-control form-control-sm form-control-xs maxw-100px"
                                    [ngClass]="{ 'is-invalid': formSubmitted && !mvSource.program_number }"
                                    [(ngModel)]="mvSource.program_number"
                                    pattern="[0-9]+"
                                />
                            </div>
                        </td>
                        <td class="maxw-130px ellipsis">
                            <div class="form-inline">
                                <input
                                    type="number"
                                    [min]="33"
                                    [max]="8190"
                                    name="basePidInput"
                                    class="ms-1 form-control form-control-sm form-control-xs maxw-100px"
                                    [ngClass]="{ 'is-invalid': formSubmitted && !mvSource.base_pid }"
                                    [(ngModel)]="mvSource.base_pid"
                                />
                            </div>
                        </td>
                        <td class="text-end">
                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary" (click)="deselectSource(mvSource)">
                                <fa-icon icon="minus" size="sm"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="sourcesLoading">
                    <tr>
                        <td colspan="5" class="text-center">{{ "LOADING" | translate }}...</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!sourcesLoading && selectedSources.length === 0">
                    <tr>
                        <td colspan="5" class="text-center">{{ "NO_SOURCES" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-hover bordered m-0" [ngClass]="{ 'is-invalid': formSubmitted && selectedSources.length <= 0 }">
                <thead>
                    <tr>
                        <th scope="col" colspan="2">{{ "SOURCES" | translate }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="!sourcesLoading && sources.length > 0">
                    <tr>
                        <td colspan="2">
                            <div class="form-inline">
                                <div class="form-group stay-inline">
                                    <label for="sourceFilter">{{ "FILTER" | translate }}:</label>
                                    <input
                                        type="text"
                                        name="sourceFilter"
                                        class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                        [(ngModel)]="sourceFilter"
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr
                        *ngFor="
                            let mvSource of availableSources
                                | filter : ['source.name', 'source.inputCluster.name'] : sourceFilter
                                | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                        "
                        class="cursor-pointer row-hover"
                        (click)="selectSource(mvSource)"
                    >
                        <td class="maxw-100px ellipsis">
                            <zx-source
                                [model]="mvSource.source"
                                [showStatusIcon]="true"
                                [showStatusText]="false"
                                [showLink]="false"
                                [showTag]="false"
                                [searchTerm]="sourceFilter"
                            ></zx-source>
                        </td>
                        <td class="text-end">
                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-primary" (click)="selectSource(mvSource)">
                                <fa-icon icon="plus" size="sm"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="sourcesLoading">
                    <tr>
                        <td colspan="5" class="text-center">{{ "LOADING" | translate }}...</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!sourcesLoading && (availableSources | filter : ['source.name', 'source.inputCluster.name'] : sourceFilter).length === 0">
                    <tr>
                        <td colspan="5" class="text-center">{{ "NO_SOURCES" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="mt-2" *ngIf="(availableSources | filter : ['source.name', 'source.inputCluster.name'] : sourceFilter).length > pageSize">
        <ngb-pagination
            class="mb-0"
            [collectionSize]="(availableSources | filter : ['source.name', 'source.inputCluster.name'] : sourceFilter).length"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="2"
        ></ngb-pagination>
    </div>
    <div class="invalid-feedback" [ngClass]="{ 'error-text': formSubmitted && selectedSources.length <= 0 }">
        <div>{{ "SOURCE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
    </div>
</div>
