<!-- Sources -->
<div class="form-group">
    <label
        for="sources"
        [ngClass]="{
            'is-invalid': formSubmitted && (!validSourceCount() || autoPullDisabled || !validSlatesCount() || !isFailoverSlateCountValid)
        }"
        >{{ "SOURCES" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
    ></label>
    <div class="table-responsive">
        <table
            class="table table-sm bordered m-0"
            [ngClass]="{ 'is-invalid': formSubmitted && (!validSourceCount() || autoPullDisabled || !validSlatesCount() || !isFailoverSlateCountValid) }"
        >
            <thead>
                <tr>
                    <th scope="col">{{ "SOURCE" | translate }}</th>
                    <th scope="col" class="w-100px">{{ "PRIORITY" | translate }}</th>
                    <th scope="col" class="w-100px">{{ "MIN_BITRATE" | translate }}[kbps]</th>
                    <th scope="col" class="w-50px text-end">
                        <fa-icon class="" icon="info-circle" [ngbTooltip]="BIContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                        <ng-template #BIContent>
                            <p class="mb-0">{{ "TOOLTIP.CHANNEL_BITRATE_INSTRUCTIONS" | translate }}</p>
                        </ng-template>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!sourcesLoading && (sources.length > 0 || selectedFailoverSources.length > 0)">
                <tr *ngFor="let failoverSource of selectedFailoverSources; index as index">
                    <td class="maxw-100px ellipsis">
                        <zx-source
                            [model]="failoverSource.source"
                            [showStatusIcon]="true"
                            [showStatusText]="false"
                            [showLink]="true"
                            [showTag]="false"
                            [ngClass]="{ 'is-invalid': formSubmitted && failoverSource.source.disable_autopull }"
                        ></zx-source>
                        <div *ngIf="formSubmitted && autoPullDisabled" class="invalid-feedback">
                            <div *ngIf="failoverSource.source.disable_autopull">{{ "AUTO_PULL_REQUIRED_FOR_FAILOVER" | translate }}.</div>
                        </div>
                    </td>
                    <td>
                        <div>
                            <div class="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="preferred_priority_{{ index }}"
                                    name="preferred_priority_{{ index }}"
                                    [(ngModel)]="failoverSource.preferred"
                                    (change)="preferredPriorityChanged(failoverSource)"
                                />
                                <label class="form-check-label" for="preferred_priority_{{ index }}">{{ "PREFERRED" | translate }}</label>
                            </div>
                            <div class="form-check form-check-inline me-0">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="backup_priority_{{ index }}"
                                    name="backup_priority_{{ index }}"
                                    [(ngModel)]="failoverSource.backup"
                                    (change)="backupPriorityChanged(failoverSource)"
                                />
                                <label class="form-check-label" for="backup_priority_{{ index }}">{{ "SLATE" | translate }}</label>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="form-inline">
                            <input
                                type="number"
                                placeholder="{{ 'MIN_BITRATE' | translate }}"
                                id="min_bitrate_{{ index }}"
                                name="min_bitrate_{{ index }}"
                                [(ngModel)]="failoverSource.min_bitrate"
                                class="ms-1 form-control form-control-sm form-control-xs"
                                pattern="^\d+$"
                                [min]="0"
                            />
                        </div>
                    </td>
                    <td class="text-end">
                        <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary" (click)="deselectFailoverSource(failoverSource)">
                            <fa-icon icon="minus" size="sm"></fa-icon>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="form-inline">
                            <div class="form-group stay-inline">
                                <label for="adaptiveSourceFilter">{{ "FILTER" | translate }}:</label>
                                <input
                                    type="text"
                                    name="adaptiveSourceFilter"
                                    class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                    [(ngModel)]="failoverSourceFilter"
                                />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr
                    *ngFor="
                        let source of sources
                            | filter : ['name', 'inputCluster.dns_prefix'] : failoverSourceFilter
                            | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                    "
                    [ngClass]="{ 'cursor-pointer row-hover': !(selectedFailoverSources.length >= maxSourceSelection) }"
                    (click)="!(selectedFailoverSources.length >= maxSourceSelection) && selectFailoverSource(source)"
                >
                    <td class="maxw-100px ellipsis">
                        <zx-source
                            [model]="source"
                            [showStatusIcon]="true"
                            [showStatusText]="false"
                            [showLink]="false"
                            [showTag]="false"
                            [searchTerm]="failoverSourceFilter"
                        ></zx-source>
                    </td>
                    <td></td>
                    <td></td>
                    <td class="text-end">
                        <button
                            type="button"
                            class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                            [disabled]="source.disable_autopull"
                            [title]="source.disable_autopull ? ('AUTO_PULL_REQUIRED_FOR_FAILOVER' | translate) : ''"
                            (click)="selectFailoverSource(source)"
                            [disabled]="selectedFailoverSources.length >= maxSourceSelection"
                        >
                            <fa-icon icon="plus" size="sm"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="sourcesLoading">
                <tr>
                    <td colspan="4" class="text-center">{{ "LOADING" | translate }}...</td>
                </tr>
            </tbody>
            <tbody *ngIf="!sourcesLoading && (sources | filter : ['name', 'inputCluster.dns_prefix'] : failoverSourceFilter).length === 0">
                <tr>
                    <td colspan="4" class="text-center">{{ "NO_SOURCES" | translate }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="formSubmitted && !validSourceCount()" class="text-invalidation">{{ "SELECT_AT_LEAST_1_SOURCE" | translate }}</div>
    <div *ngIf="formSubmitted && !validSlatesCount()" class="text-invalidation">{{ "SLATE_SOURCE_REQUIRED" | translate }}</div>
    <div *ngIf="formSubmitted && !isFailoverSlateCountValid" class="text-invalidation">
        {{ "UP_TO_1_SLATE_STREAMS_SUPPORTED" | translate }}
    </div>
    <div class="mt-2" *ngIf="sources && (sources | filter : ['name', 'inputCluster.dns_prefix'] : failoverSourceFilter).length > pageSize">
        <ngb-pagination
            class="mb-0"
            [collectionSize]="(sources | filter : ['name', 'inputCluster.dns_prefix'] : failoverSourceFilter).length"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="2"
        ></ngb-pagination>
    </div>
</div>
