<fieldset
    ngModelGroup="media_stream_{{ model.id }}"
    #mediaStream="ngModelGroup"
    class="form-group bordered mb-2"
    [ngClass]="{
        collapsed:
            collapsed &&
            !((sourceSelection && source && source.protocol === 'st2110-jpegxs') || (outputSelection && jpegXSOutput)) &&
            !(((source && source.protocol === 'cdi') || !source) && outputSelection && jpegXSOutput && model.stream_type === 'video') &&
            !outputSelection
    }"
>
    <legend>
        <fa-icon class="me-1" icon="plus" size="xs" [fixedWidth]="true" *ngIf="collapsed" (click)="expandControls()"></fa-icon
        ><fa-icon class="me-1" icon="minus" size="xs" [fixedWidth]="true" *ngIf="!collapsed" (click)="collapseControls()"></fa-icon
        >{{ model && model.name ? model.name : "New" }}
    </legend>
    <div *ngIf="outputSelection" class="form-group mb-1">
        <div class="form-check">
            <input
                type="checkbox"
                id="mc_stream_check_{{ model.id }}"
                name="mc_stream_check_{{ model.id }}"
                class="form-check-input"
                [(ngModel)]="model.inUse"
                (change)="mediaStreamSendSelectionChanged()"
            />
            <label class="form-check-label" for="mc_stream_check_{{ model.id }}">{{ "SEND_STREAM" | translate }}</label>
        </div>
    </div>

    <div [ngClass]="{ 'd-none': collapsed }">
        <!-- Name -->
        <div class="form-group mb-2">
            <label for="mediaStreamName" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamName && mediaStreamName.errors }"
                >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon><fa-icon icon="lock" size="sm"></fa-icon
            ></label>
            <input
                type="text"
                id="mediaStreamName"
                name="mediaStreamName"
                placeholder="{{ 'NAME' | translate }}"
                class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamName.errors }"
                [(ngModel)]="model.name"
                [disabled]="!removeAllowed || isEdit"
                (change)="nameChanged()"
                required
                #mediaStreamName="ngModel"
                pattern="{{ constants.validators.name }}"
                duplicateName="{{ streamNames.join(',') }}"
            />
            <div *ngIf="mediaStreamName.invalid && model.inUse" class="invalid-feedback">
                <div *ngIf="mediaStreamName.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                <div *ngIf="mediaStreamName.errors.pattern">
                    {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                </div>
                <div *ngIf="mediaStreamName.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
            </div>
        </div>
        <!-- Type -->
        <div class="form-group mb-2">
            <label for="mediaStreamType" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamType.errors }">
                {{ "TYPE" | translate }}
            </label>
            <ng-select
                id="mediaStreamType"
                name="mediaStreamType"
                class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamType.errors }"
                [items]="mediaStreamTypes"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'TYPE' | translate }}"
                [(ngModel)]="model.stream_type"
                [disabled]="!removeAllowed || !model.name || model.name === ''"
                bindLabel="name"
                bindValue="id"
                #mediaStreamType="ngModel"
                maxSelection="{{ runMediaStreamTypeSelectionQuery() }}"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamType.errors }"
                (change)="mediaStreamTypeChanged()"
            >
            </ng-select>
            <div *ngIf="mediaStreamType.invalid && model.inUse" class="invalid-feedback">
                <div *ngIf="mediaStreamType.errors.overLimit === 'ancillary-data'">{{ "UP_TO_1_DATA_STREAM" | translate }}.</div>
                <div *ngIf="mediaStreamType.errors.overLimit === 'video'">{{ "UP_TO_1_VIDEO_STREAM" | translate }}</div>
            </div>
        </div>
        <!-- Stream ID -->
        <div class="form-group mb-2">
            <label for="mediaStreamStreamID" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamStreamID.errors }"
                >{{ "STREAM_ID" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon><fa-icon icon="lock" size="sm"></fa-icon
            ></label>
            <input
                type="number"
                id="mediaStreamStreamID"
                name="mediaStreamStreamID"
                placeholder="{{ 'STREAM_ID' | translate }}"
                class="form-control form-control-sm"
                [disabled]="isEdit || !model.name || model.name === ''"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamStreamID.errors }"
                [(ngModel)]="model.stream_id"
                required
                [min]="0"
                #mediaStreamStreamID="ngModel"
                (change)="streamIDChanged()"
                dynamicDuplicate="{{ streamIDs.join(',') }}"
            />
            <small>{{ "MC_FLOW_STREAM_ID_NOTE" | translate }}</small>
            <div *ngIf="mediaStreamStreamID.invalid && model.inUse" class="invalid-feedback">
                <div *ngIf="mediaStreamStreamID.errors.required">{{ "STREAM_ID" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                <div *ngIf="mediaStreamStreamID.errors.duplicateValue">{{ "STREAM_ID" | translate }} {{ "MUST_BE_UNIQUE" | translate }}</div>
            </div>
        </div>
        <!-- Encoding name -->
        <div class="form-group mb-2" *ngIf="sourceSelection || outputSelection">
            <label for="mediaStreamEncoding">{{ "MEDIACONNECT_MEDIAENCODING" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon></label>
            <input
                type="text"
                id="mediaStreamEncoding"
                name="mediaStreamEncoding"
                placeholder="{{ 'MEDIACONNECT_MEDIAENCODING' | translate }}"
                class="form-control form-control-sm"
                [disabled]="true"
                [(ngModel)]="model.encoding_name"
            />
        </div>
        <!-- Clock Rate -->
        <div class="form-group mb-2">
            <label for="mediaStreamClockRate" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamClockRate.errors }">
                {{ "MEDIA_CLOCK_RATE" | translate }}
            </label>
            <ng-select
                id="mediaStreamClockRate"
                name="mediaStreamClockRate"
                class="form-control form-control-sm"
                [items]="model.stream_type === 'audio' ? [48000, 96000] : [90000]"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'MEDIA_CLOCK_RATE' | translate }}"
                [(ngModel)]="model.clock_rate"
                [disabled]="model.stream_type !== 'audio' || !model.name || model.name === ''"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamClockRate.errors }"
                #mediaStreamClockRate="ngModel"
            >
            </ng-select>
            <small translate>MC_NOTES.CLOCK_RATE</small>
        </div>
        <!-- Language -->
        <div class="form-group mb-2" *ngIf="model && model.stream_type === 'audio'">
            <label for="mediaStreamLang" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamLang.errors }"
                >{{ "LANGUAGE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
            ></label>
            <input
                type="text"
                id="mediaStreamLang"
                name="mediaStreamLang"
                placeholder="{{ 'LANGUAGE' | translate }}"
                class="form-control form-control-sm"
                #mediaStreamLang="ngModel"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamLang.errors }"
                [(ngModel)]="model.audio_language"
                [disabled]="!model.name || model.name === ''"
                required
            />
            <small translate>MC_NOTES.LANG</small>
            <div *ngIf="mediaStreamLang.invalid && model.inUse" class="invalid-feedback">
                <div *ngIf="mediaStreamLang.errors.required">{{ "LANGUAGE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
            </div>
        </div>
        <!-- Channel order -->
        <div class="form-group mb-2" *ngIf="model && model.stream_type === 'audio'">
            <label for="mediaStreamChanOrder" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamChanOrder.errors }">
                {{ "MEDIA_CHANNEL_ORDER" | translate }}
            </label>
            <ng-select
                id="mediaStreamChanOrder"
                name="mediaStreamChanOrder"
                class="form-control form-control-sm"
                #mediaStreamChanOrder="ngModel"
                [items]="mediaStreamAudioChannelOrders"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'MEDIA_CHANNEL_ORDER' | translate }}"
                [(ngModel)]="model.audio_channel_order"
                bindLabel="name"
                bindValue="id"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamChanOrder.errors }"
                [disabled]="!model.name || model.name === ''"
            >
            </ng-select>
            <small translate>MC_NOTES.CHANNEL_ORDER</small>
        </div>
        <!-- Video Format -->
        <div class="form-group mb-2" *ngIf="model && model.stream_type === 'video'">
            <label for="mediaStreamVideoFormat" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoFormat.errors }">
                {{ "MEDIA_VIDEO_FORMAT" | translate }}
            </label>
            <ng-select
                id="mediaStreamVideoFormat"
                name="mediaStreamVideoFormat"
                class="form-control form-control-sm"
                [items]="mediaStreamVideoFormats"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'MEDIA_VIDEO_FORMAT' | translate }}"
                [(ngModel)]="model.video_format"
                bindLabel="name"
                bindValue="id"
                #mediaStreamVideoFormat="ngModel"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoFormat.errors }"
                [disabled]="!model.name || model.name === ''"
            >
            </ng-select>
        </div>
        <!-- Exact Framerate -->
        <div class="form-group mb-2" *ngIf="model && model.stream_type === 'video'">
            <label for="mediaStreamVideoExactFrameRate" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoExactFrameRate.errors }"
                >{{ "EXACT_FRAMERATE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
            ></label>
            <input
                type="text"
                id="mediaStreamVideoExactFrameRate"
                name="mediaStreamVideoExactFrameRate"
                placeholder="{{ 'EXACT_FRAMERATE' | translate }}"
                class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoExactFrameRate.errors }"
                [(ngModel)]="model.video_exact_frame_rate"
                #mediaStreamVideoExactFrameRate="ngModel"
                required
                pattern="^[1-9]\d*(|\/[1-9]\d*)$"
                [disabled]="!model.name || model.name === ''"
            />
            <div *ngIf="mediaStreamVideoExactFrameRate.invalid && model.inUse" class="invalid-feedback">
                <div *ngIf="mediaStreamVideoExactFrameRate.errors.required || mediaStreamVideoExactFrameRate.errors.pattern">
                    {{ "EXACT_FRAME_RATE_PATTERN_ERROR" | translate }}.
                </div>
            </div>
            <small translate>MC_NOTES.EXACT_FRAME_RATE</small>
        </div>
        <!-- Colorimetry -->
        <div class="form-group mb-2" *ngIf="model && model.stream_type === 'video'">
            <label for="mediaStreamVideoColorimetry" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoColorimetry.errors }">
                {{ "COLORIMETRY" | translate }}
            </label>
            <ng-select
                id="mediaStreamVideoColorimetry"
                name="mediaStreamVideoColorimetry"
                class="form-control form-control-sm"
                #mediaStreamVideoColorimetry="ngModel"
                [items]="mediaStreamVideoColorimetryOptions"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'COLORIMETRY' | translate }}"
                [(ngModel)]="model.video_colorimetry"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoColorimetry.errors }"
                [disabled]="!model.name || model.name === ''"
            >
            </ng-select>
        </div>
        <!-- Scan Mode -->
        <div class="form-group mb-2" *ngIf="model && model.stream_type === 'video'">
            <label for="mediaStreamVideoScanMode" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoScanMode.errors }">
                {{ "SCAN_MODE" | translate }}
            </label>
            <ng-select
                id="mediaStreamVideoScanMode"
                name="mediaStreamVideoScanMode"
                class="form-control form-control-sm"
                #mediaStreamVideoScanMode="ngModel"
                [items]="mediaStreamScanModes"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'SCAN_MODE' | translate }}"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="model.video_scan_mode"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoScanMode.errors }"
                [disabled]="!model.name || model.name === ''"
            >
            </ng-select>
        </div>
        <!-- TCS -->
        <div class="form-group mb-2" *ngIf="model && model.stream_type === 'video'">
            <label for="mediaStreamVideoTSC" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoTSC.errors }">
                {{ "TCS" | translate }}
            </label>
            <ng-select
                id="mediaStreamVideoTSC"
                name="mediaStreamVideoTSC"
                class="form-control form-control-sm"
                #mediaStreamVideoTSC="ngModel"
                [items]="mediaStreamVideoTscOptions"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'TSC' | translate }}"
                [(ngModel)]="model.video_tcs"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoTSC.errors }"
                [disabled]="!model.name || model.name === ''"
            >
            </ng-select>
            <small translate>MC_NOTES.TSC</small>
        </div>
        <!-- Range -->
        <div class="form-group mb-2" *ngIf="model && model.stream_type === 'video'">
            <label for="mediaStreamVideoRange" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoRange.errors }">
                {{ "RANGE" | translate }}
            </label>
            <ng-select
                id="mediaStreamVideoRange"
                name="mediaStreamVideoRange"
                class="form-control form-control-sm"
                #mediaStreamVideoRange="ngModel"
                [items]="mediaStreamVideoRanges"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'RANGE' | translate }}"
                [(ngModel)]="model.video_range"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoRange.errors }"
                [disabled]="!model.name || model.name === ''"
            >
            </ng-select>
            <small translate>MC_NOTES.RANGE</small>
        </div>
        <!-- Par -->
        <div class="form-group mb-2" *ngIf="model && model.stream_type === 'video'">
            <label for="mediaStreamVideoPar" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoPar.errors }"
                >{{ "PAR" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
            ></label>
            <input
                type="text"
                id="mediaStreamVideoPar"
                name="mediaStreamVideoPar"
                placeholder="{{ 'PAR' | translate }}"
                class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamVideoPar.errors }"
                [(ngModel)]="model.video_par"
                #mediaStreamVideoPar="ngModel"
                required
                pattern="^[1-9]\d*:[1-9]\d*$"
                [disabled]="!model.name || model.name === ''"
            />
            <div *ngIf="mediaStreamVideoPar.invalid" class="invalid-feedback">
                <div *ngIf="mediaStreamVideoPar.errors.required || mediaStreamVideoPar.errors.pattern">{{ "EXACT_FRAME_RATE_PATTERN_ERROR" | translate }}.</div>
            </div>
            <small translate>MC_NOTES.PAR</small>
        </div>
    </div>

    <!-- Port -->
    <div class="pt-0" *ngIf="model.inUse">
        <div class="form-group mb-2" *ngIf="(sourceSelection && source && source.protocol === 'st2110-jpegxs') || (outputSelection && jpegXSOutput)">
            <label for="mediaStreamPort" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamPort.errors }"
                >{{ "PORT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
            ></label>
            <input
                type="number"
                id="mediaStreamPort"
                name="mediaStreamPort"
                placeholder="{{ 'PORT' | translate }}"
                class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamPort.errors }"
                [(ngModel)]="model.port"
                [disabled]="!model.name || model.name === ''"
                #mediaStreamPort="ngModel"
                required="{{ model.inUse }}"
                pattern="^[1-9]\d*$"
                [min]="1024"
                [max]="65535"
                dynamicDuplicate="{{ streamPorts.join(',') }}"
                (change)="portChanged()"
            />
            <div *ngIf="mediaStreamPort.invalid && model.inUse" class="invalid-feedback">
                <div *ngIf="mediaStreamPort.errors.required">{{ "PORT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                <div *ngIf="mediaStreamPort.errors.pattern">{{ "INVALID_PORT_RANGE" | translate }}.</div>
                <div *ngIf="mediaStreamPort.errors.duplicateValue">{{ "PORT" | translate }} {{ "MUST_BE_UNIQUE" | translate }}</div>
            </div>
        </div>

        <!-- Encoding profile -->
        <div
            class="form-group mb-2"
            *ngIf="((source && source.protocol === 'cdi') || !source) && outputSelection && jpegXSOutput && model.stream_type === 'video'"
        >
            <label for="mediaStreamProfile" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamProfile.errors }"
                >{{ "ENCODING_PROFILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
            ></label>
            <ng-select
                id="mediaStreamProfile"
                name="mediaStreamProfile"
                class="form-control form-control-sm"
                #mediaStreamProfile="ngModel"
                [items]="mediaEncodingProfiles"
                [clearable]="false"
                [disabled]="!model.name || model.name === ''"
                placeholder="{{ 'SELECT' | translate }} {{ 'ENCODING_PROFILE' | translate }}"
                [(ngModel)]="model.encoding_profile"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamProfile.errors }"
                required="{{ model.inUse }}"
            ></ng-select>
            <div *ngIf="mediaStreamProfile.invalid && model.inUse" class="invalid-feedback">
                <div *ngIf="mediaStreamProfile.errors.required">{{ "ENCODING_PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
            </div>
        </div>

        <!-- Encoding Compression -->
        <div
            class="form-group mb-2"
            *ngIf="((source && source.protocol === 'cdi') || !source) && outputSelection && jpegXSOutput && model.stream_type === 'video'"
        >
            <label for="mediaStreamCompression" [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamCompression.errors }"
                >{{ "ENCODING_COMPRESSION" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
            ></label>
            <input
                type="number"
                id="mediaStreamCompression"
                name="mediaStreamCompression"
                #mediaStreamCompression="ngModel"
                placeholder="{{ 'ENCODING_COMPRESSION' | translate }}"
                class="form-control form-control-sm"
                [disabled]="!model.name || model.name === ''"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && mediaStreamCompression.errors }"
                [(ngModel)]="model.encoding_compression"
                [min]="3.0"
                [max]="10.0"
                required="{{ model.inUse }}"
            />
            <small translate>COMPRESSION_RANGE_NOTE</small>
            <div *ngIf="mediaStreamCompression.invalid && model.inUse" class="invalid-feedback">
                <div *ngIf="mediaStreamCompression.errors.required">{{ "ENCODING_COMPRESSION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                <div *ngIf="mediaStreamCompression.errors.pattern">{{ "INVALID_COMPRESSION_VALUE" | translate }}.</div>
            </div>
        </div>
    </div>
    <!-- Button -->
    <div [ngClass]="{ 'd-none': collapsed }">
        <button *ngIf="removeAllowed" type="button" class="btn btn-sm btn-outline-primary float-start clear-both" (click)="removeMediaStream()">
            <fa-icon icon="minus" size="sm" class="me-1"></fa-icon>{{ "REMOVE_MEDIA_STREAM" | translate }}
        </button>
    </div>
</fieldset>
