<div class="details-form d-flex flex-wrap flex-row grid-gap-3">
    <div class="flex-1 flex-basis-min">
        <div class="form-group">
            <h5 translate>TYPE</h5>
            <div>
                {{ source._frontData.typeColumn }}
            </div>
        </div>
        <div class="form-group" *ngIf="source.alertingProfile">
            <h5 translate>ALERTING_PROFILE</h5>
            <div>
                <a [routerLink]="['/' + urls.configuration.eventsManagement, source.alertingProfile.id]">
                    {{ source.alertingProfile.name }}
                </a>
            </div>
        </div>
        <div class="form-group" *ngIf="source.multiplexSources && source.multiplexSources.length > 0">
            <h5 translate>SOURCES</h5>
            <div>
                <div class="ellipsis" *ngFor="let mpx of source.multiplexSources">
                    <zx-source [model]="mpx.source" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="false"></zx-source>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="multiviewSources?.length > 0">
            <h5>{{ "SOURCES" | translate }} (x,y)</h5>
            <div>
                <div class="ellipsis" *ngFor="let mv of multiviewSources">
                    <span>{{ mv.grid_x }},{{ mv.grid_y }}</span
                    >&nbsp;
                    <zx-source [model]="mv.source" [showLink]="true" [showTag]="false"></zx-source>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="primaryFailoverSources.length > 0">
            <h5>{{ "PRIMARY" | translate }} {{ "SOURCES" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let fs of primaryFailoverSources">
                    <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
                    <span *ngIf="fs.is_active" class="ms-1">(Active)</span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="secondaryFailoverSources.length > 0">
            <h5>{{ "SECONDARY" | translate }} {{ "SOURCES" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let fs of secondaryFailoverSources">
                    <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
                    <span *ngIf="fs.is_active" class="ms-1">(Active)</span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="slateFailoverSources.length > 0">
            <h5>{{ "SLATE" | translate }} {{ "SOURCES" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let fs of slateFailoverSources">
                    <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
                    <span *ngIf="fs.is_active" class="ms-1">(Active)</span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="source.transcodeProfile">
            <h5 translate>TRANSCODING_PROFILE</h5>
            <div>
                <a [routerLink]="['/' + urls.transformation.transcoding_profiles, source.transcodeProfile.name]">
                    {{ source.transcodeProfile.name }}
                </a>
            </div>
        </div>
        <div class="form-group" *ngIf="source.pid_mapping && source.pid_mapping.name">
            <h5 translate>PID_MAPPING</h5>
            <div>
                <a [routerLink]="['/' + urls.transformation.pid_mapping_profiles, source.pid_mapping.name]">
                    {{ source.pid_mapping.name }}
                </a>
            </div>
        </div>
        <div class="form-group" *ngIf="source.type === 'file' && source.protocol === 'http_file'">
            <h5 translate>URL</h5>
            <div>
                <span
                    >{{ source.url
                    }}<button class="btn btn-sm btn-xs btn-copy" (click)="copyPassword(source.url)" type="button" title="{{ 'COPY' | translate }}">
                        <fa-icon icon="clipboard" size="1x"></fa-icon></button
                ></span>
            </div>
        </div>
        <div
            class="form-group"
            *ngIf="
                source.feeder_id ||
                source.broadcaster_id ||
                source.input_id ||
                (source.transcode_profile_id && source.type !== 'multiview') ||
                source.Source ||
                source._frontData.input_description
            "
        >
            <h5 translate>INPUT</h5>
            <div>
                <zx-feeder *ngIf="source.feeder_id" [model]="source.feeder" [showTag]="true" [showLink]="true" [showStatusIcon]="true"></zx-feeder>
                <zx-broadcaster
                    *ngIf="source.broadcaster_id"
                    [model]="source.broadcaster"
                    [showTag]="true"
                    [showLink]="true"
                    [showStatusIcon]="true"
                ></zx-broadcaster>
                <span *ngIf="source.input_id && source.type !== 'zixi_pull' && source.type !== 'rtmp'"
                    ><span *ngIf="source.broadcaster_id || source.feeder_id">&nbsp;/&nbsp;</span>{{ source.input_id
                    }}<span *ngIf="source.Source">&nbsp;/&nbsp;</span></span
                >
                <span *ngFor="let failoverSource of source.failoverSources; let isLast = last"
                    >{{ failoverSource.source.name }}{{ isLast ? "" : ",&nbsp;" }}</span
                >
                <span *ngIf="source.transcode_profile_id && source.transcodeSource && source.transcodeSource.name"> {{ source.transcodeSource.name }} </span>
                <zx-source *ngIf="source.Source" [model]="source.Source" [showLink]="true" [showTag]="false"></zx-source>
                <span *ngIf="source._frontData.input_description">
                    {{ source._frontData.input_description }}
                </span>
            </div>
        </div>
        <app-details-location [location]="source.location"></app-details-location>

        <div class="form-group" *ngIf="source.status && source.status.source_ip && source.type !== 'file'">
            <h5 translate>SOURCE_IP</h5>
            <div>
                <span>{{ source.status.source_ip }}</span>
            </div>
        </div>

        <app-generic-detail
            *ngIf="source.protocol === 'rist'"
            header="PROFILE"
            [content]="source.rist_profile ? (source.rist_profile.toUpperCase() | translate) : ''"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.protocol === 'rist'"
            header="MODE"
            [content]="source.type === 'rist_pull' ? ('PULL' | translate) : ('PUSH' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'rtmp'"
            header="MODE"
            [content]="source.pull_mode ? ('PULL' | translate) : ('PUSH' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.merge_mode === 'none' && source.type === 'hitless'"
            header="HITLESS_PRIORITY_UPGRADE"
            [content]="source.allow_failover_priority_upgrade ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'hitless'"
            header="FAILOVER_MERGE_MODE"
            [content]="
                source.merge_mode === 'none'
                    ? ('NON_HITLESS' | translate)
                    : source.merge_mode === 'rtp'
                    ? ('SMPTE_2022-7_HITLESS' | translate)
                    : source.merge_mode === 'content'
                    ? ('CONTENT_HITLESS' | translate)
                    : ''
            "
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'hitless'"
            [header]="source.merge_mode !== 'none' ? ('MERGE_SEARCH_WINDOW' | translate) : ('OFFLINE_FAILOVER_WINDOW' | translate)"
            [content]="source.latency ?? ''"
            unit="ms"
        ></app-generic-detail>

        <app-generic-detail
            *ngIf="source.type === 'rtp' || source.type === 'udp' || source.protocol === 'rist'"
            header="MULTICAST_ADDRESS"
            [content]="source.multicast_address ?? ''"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'rtp' || source.type === 'udp' || source.protocol === 'rist'"
            header="MULTICAST_SOURCE"
            [content]="source.multicast_source ?? ''"
        ></app-generic-detail>
        <app-generic-detail header="REMOTE_HOST" [content]="source.remote_host ?? ''"></app-generic-detail>
        <app-generic-detail header="REMOTE_PORT" [content]="source.remote_port ?? ''"></app-generic-detail>
        <div
            class="form-group"
            *ngIf="
                source &&
                source.type !== 'file' &&
                source.type !== 'rtmp' &&
                source.type !== 'hls_pull' &&
                ((source._frontData.cluster?.auth_param && source._frontData.cluster?.auth_mode === 'password') ||
                    (source.password && source._frontData.cluster?.auth_mode !== 'password')) &&
                (!source._frontData.cluster?.auth_mode ||
                    source._frontData.cluster?.auth_mode === 'zen' ||
                    source._frontData.cluster?.auth_mode === 'password' ||
                    source._frontData.cluster?.auth_mode === 'custom')
            "
        >
            <h5 translate>SOURCE_PASSWORD</h5>
            <div *ngIf="source._frontData.cluster?.auth_mode === 'password'">
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">
                    {{ "SHOW" | translate }}
                </a>
                <span *ngIf="pwVisible">{{ source._frontData.cluster?.auth_param }}</span> /
                <a href="javascript:void(0)" (click)="copyPassword(source._frontData.cluster?.auth_param)">
                    {{ "COPY" | translate }}
                </a>
            </div>
            <div *ngIf="source._frontData.cluster?.auth_mode !== 'password'">
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">
                    {{ "SHOW" | translate }}
                </a>
                <span *ngIf="pwVisible">{{ source.password }}</span> /
                <a href="javascript:void(0)" (click)="copyPassword(source.password)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <div class="form-group" *ngIf="source.html_overlay_url">
            <h5 translate>HTML_OVERLAY_URL</h5>
            <div>
                <span>{{ source.html_overlay_url }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>INGEST_CLUSTER</h5>
            <div>
                <div class="ellipsis">
                    <div class="d-inline" *ngIf="source.target_broadcaster_id > 0 || source.activeBroadcasterObjects?.bx_id">
                        <zx-broadcaster
                            [broadcasterId]="source.target_broadcaster_id > 0 ? source.target_broadcaster_id : source.activeBroadcasterObjects?.bx_id"
                            [clusterId]="source.broadcaster_cluster_id"
                            [showTag]="false"
                            [showLink]="true"
                            [showStatusIcon]="true"
                        ></zx-broadcaster>
                        @
                    </div>
                    <zx-cluster
                        class="d-inline-block"
                        [model]="source.inputCluster"
                        [showOtherIcons]="false"
                        [showStatusIcon]="false"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                    ></zx-cluster>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="source.target_broadcaster_id">
            <h5 translate>TARGET_BROADCASTER/S</h5>
            <div>
                <span *ngIf="source.target_broadcaster_id === -1">{{ "PREFER_PRIMARY_BROADCASTERS" | translate }}</span>
                <span *ngIf="source.target_broadcaster_id === -2">{{ "PRIMARY_BROADCASTERS_ONLY" | translate }}</span>
                <span *ngIf="source.target_broadcaster_id === -3">{{ "BACKUP_BROADCASTERS_ONLY" | translate }}</span>
                <div *ngIf="source.target_broadcaster_id !== -3 && source.target_broadcaster_id !== -2 && source.target_broadcaster_id !== -1">
                    <zx-broadcaster
                        *ngIf="targetBroadcaster"
                        [model]="targetBroadcaster"
                        [showTag]="false"
                        [showLink]="true"
                        [showStatusIcon]="true"
                        [clusterId]="source.broadcaster_cluster_id"
                    ></zx-broadcaster>
                </div>
            </div>
        </div>
        <app-generic-detail
            *ngIf="source.type === 'intercluster'"
            header="PREFER_PRIVATE_BROADCASTER_IP"
            [content]="source.use_private_ip ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>

        <app-generic-detail header="APPLICATION" [content]="source?.status?.app"></app-generic-detail>
        <app-generic-detail header="DEVICE" [content]="source?.status?.device"></app-generic-detail>
        <app-generic-detail header="BUILD" [content]="source?.status?.build"></app-generic-detail>
        <app-generic-detail header="ENCRYPTED" [content]="source?.status?.encryption_description || 'No'"></app-generic-detail>
        <app-generic-detail header="REMOTE_ID" [content]="source?.status?.remote_id"></app-generic-detail>
        <app-generic-detail header="LIVE_RECORDING" [content]="source?.status?.live_recording_status"></app-generic-detail>
        <app-generic-detail header="LIVE_RECORDING_ERROR" [content]="source?.status?.live_recording_status_last_error"></app-generic-detail>
        <app-generic-detail header="CERTIFICATE" [content]="source?.status?.cert"></app-generic-detail>
        <app-generic-detail header="RTP_PAYLOAD" [content]="source?.status?.rtp_payload ? 'RTP Payload' : ''"></app-generic-detail>
    </div>
    <div class="flex-1 flex-basis-min">
        <app-generic-detail
            *ngIf="source.target_bitrate && source.type === 'multiplex'"
            header="TARGET_BITRATE"
            [content]="source.target_bitrate ?? ''"
            unit="kbps"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.gpu_id && (source.type === 'ndi' || source.type === 'transcoded')"
            header="ASSIGN_TO_GPU"
            [content]="source.gpu_id ?? ''"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.target_bitrate && source.type === 'demux'"
            header="PAD_TO_CBR"
            [content]="source.target_bitrate ? source.target_bitrate / 1000 : ''"
            unit="kbps"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.transcode_cbr_kbps && (source.type === 'ndi' || source.type === 'transcoded')"
            header="PAD_TO_CBR"
            [content]="source.transcode_cbr_kbps ?? ''"
            unit="kbps"
        ></app-generic-detail>

        <app-generic-detail
            *ngIf="source.type === 'srt' && source.srt_mode === 'push'"
            header="VERIFY_STREAM_ID"
            [content]="source.verify_srt_stream_id ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'rtp' || source.protocol === 'rist'"
            header="USE_FEC"
            [content]="source.use_fec ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.rist_profile === 'main'"
            header="USE_DTLS"
            [content]="source.use_dtls ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'rtp' || source.type === 'hitless'"
            header="KEEP_RTP_HEADERS"
            [content]="source.keep_rtp_headers ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>

        <app-generic-detail
            *ngIf="source.audio_ref_level && source.type === 'ndi'"
            header="NDI_AUDIO_REF"
            [content]="source.audio_ref_level ?? ''"
            unit="dB"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.target_bitrate && source.type === 'demux'"
            header="PCR_INTERVAL"
            [content]="source.pcr_interval_ms ?? ''"
            unit="ms"
        ></app-generic-detail>
        <div class="form-group" *ngIf="source.feeder_id || source.srt_mode || source.rist_profile || source?.status?.maxbitrate">
            <h5 translate>MAX_BITRATE</h5>
            <div>
                <span>{{
                    source.feeder_id || source.srt_mode || source.rist_profile
                        ? (source.max_bitrate | bitrate)
                        : source?.status?.maxbitrate
                        ? (source?.status?.maxbitrate | bitrate)
                        : ""
                }}</span>
            </div>
        </div>
        <app-generic-detail
            *ngIf="
                (source.feeder_id ||
                    source.broadcaster_id ||
                    source.zec_id ||
                    source.mediaconnect_flow_id ||
                    source.srt_mode ||
                    source.rist_profile ||
                    source.type === 'intercluster') &&
                source.latency
            "
            header="LATENCY"
            [content]="source.latency ?? ''"
            unit="ms"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'hls_pull' && source.latency"
            header="BUFFER_SIZE"
            [content]="source.latency ?? ''"
            unit="ms"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'hls_pull'"
            header="HLS_PULL_INJECT_SCTE_CUE"
            [content]="source.inject_scte_on_cue ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'rtmp' && source.pull_mode"
            header="IGNORE_TLS_ERRORS"
            [content]="source.ignore_tls_errors ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'rtmp'"
            header="DISCONNECT_IDLE_STREAM"
            [content]="source.disconnect_on_idle ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <div class="form-group">
            <h5 translate>PUBLIC_OUTPUT</h5>
            <div>
                <span *ngIf="!source.allow_outputs">{{ "DISABLED" | translate }}</span>
                <span *ngIf="source.allow_outputs">{{ source.public_url }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5>{{ "CLOSED_CAPTION_PRESERVE" | translate }}</h5>
            <div>
                <span *ngIf="source.copy_closed_captions">{{ "ENABLED" | translate }}</span>
                <span *ngIf="!source.copy_closed_captions">{{ "DISABLED" | translate }}</span>
            </div>
        </div>
        <app-generic-detail
            *ngIf="source.type === 'multiplex'"
            header="DROP_INACTIVE_MULTIPLEX"
            [content]="source.drop_inactive_multiplex ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail header="PRIORITY_SOURCE_ID" [content]="source.priority_client_id ?? ''"></app-generic-detail>

        <app-generic-detail
            *ngIf="source.type !== 'file'"
            header="ALERT_ON_PID_CHANGES"
            [content]="source.monitor_pids_change ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="['zixi_pull', 'zixi_push', 'other', 'mediaconnect', 'feeder', 'intercluster'].includes(source.type)"
            header="MINIMIZE_LATENCY"
            [content]="source.minimize_latency ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>

        <div class="form-group">
            <h5 translate>CONTENT_ANALYSIS</h5>
            <div>
                <span *ngIf="contentAnalysis === CONTENT_ANALYSIS.FULL">{{ "FULL" | translate }}</span>
                <span *ngIf="contentAnalysis === CONTENT_ANALYSIS.TR101_ONLY">{{ "TR101_ONLY" | translate }}</span>
                <span *ngIf="contentAnalysis === CONTENT_ANALYSIS.NONE">{{ "NONE" | translate }}</span>
            </div>
        </div>
        <app-generic-detail header="HIDE_THUMBNAIL" [content]="source.hide_thumbnail ? ('ENABLED' | translate) : ('DISABLED' | translate)"></app-generic-detail>
        <div class="form-group" *ngIf="source.process_scte_reports && source.type !== 'file'">
            <h5 translate>LOG_SCTE_35</h5>
            <div>
                <span *ngIf="source.inputCluster.configure_scte_reports">{{ "ENABLED" | translate }}</span>
                <span *ngIf="!source.inputCluster.configure_scte_reports">{{ "DISABLED" | translate }} {{ "ON" | translate }} {{ "CLUSTER" | translate }}</span>
            </div>
        </div>
        <app-generic-detail
            *ngIf="source.type !== 'file'"
            header="SCTE_35_ALERTS"
            [content]="source.report_scte_warnings ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="source.type === 'transcoded'"
            header="IGNORE_TRANSCODE_THRESHOLDS"
            [content]="source.ignore_transcode_thresholds ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            header="ENABLE_SCTE35_INSERTION"
            [content]="source.enable_scte35_insertion ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <div class="form-group" *ngIf="source.disable_autopull">
            <h5 translate>AUTO_PULL</h5>
            <div>
                <span>{{ "DISABLED" | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="!source.disable_autopull && source.autopull_latency">
            <h5>{{ "AUTO_PULL" | translate }} {{ "LATENCY" | translate }} [ms]</h5>
            <div>
                <span>{{ source.autopull_latency }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="source.type === 'transcoded' && source.transcode_crop_mode !== 0">
            <h5 translate>CROP</h5>
            <div class="d-flex">
                <div class="me-2">
                    <label>{{ "X_LEFT" | translate }}:</label> {{ source.transcode_crop_left }}<span *ngIf="source.transcode_crop_mode === 1">px</span
                    ><span *ngIf="source.transcode_crop_mode === 2">%</span>
                </div>
                <div class="me-2">
                    <label>{{ "Y_TOP" | translate }}:</label> {{ source.transcode_crop_top }}<span *ngIf="source.transcode_crop_mode === 1">px</span
                    ><span *ngIf="source.transcode_crop_mode === 2">%</span>
                </div>
                <div class="me-2">
                    <label>{{ "WIDTH" | translate }}:</label> {{ source.transcode_crop_width }}<span *ngIf="source.transcode_crop_mode === 1">px</span
                    ><span *ngIf="source.transcode_crop_mode === 2">%</span>
                </div>
                <div>
                    <label>{{ "HEIGHT" | translate }}:</label> {{ source.transcode_crop_height }}<span *ngIf="source.transcode_crop_mode === 1">px</span
                    ><span *ngIf="source.transcode_crop_mode === 2">%</span>
                </div>
            </div>
        </div>

        <!-- Error Concealment -->
        <div *ngIf="source.type === 'hitless' && source.error_concealment">
            <h4 translate>ERROR_CONCEALMENT</h4>
            <app-generic-detail header="ERROR_CONCEALMENT" [content]="source.error_concealment ? ('ENABLED' | translate) : ''"></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_CONTINUOUS_TIMELINE"
                [content]="source.error_concealment_continuous_timeline ? ('ENABLED' | translate) : ''"
            ></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_REPLACE_FRAMES"
                [content]="source.error_concealment_replace_frames ? ('ENABLED' | translate) : ''"
            ></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_FIX_CC"
                [content]="source.error_concealment_fix_cc ? ('ENABLED' | translate) : ''"
            ></app-generic-detail>
            <app-generic-detail header="ERROR_CONCEALMENT_DELAY" unit="ms" [content]="source.error_concealment_delay_ms ?? ''"></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_CBR_PADDING"
                unit="kbps"
                [content]="source.error_concealment_cbr_padding_kbps ?? ''"
            ></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_CBR_PADDING_PCR_INTERVAL"
                unit="ms"
                [content]="source.error_concealment_cbr_padding_pcr_interval_ms ?? ''"
            ></app-generic-detail>
        </div>

        <!-- Advanced -->
        <div>
            <app-copy-credentials
                *ngIf="source.billing_code"
                [user]="source.billing_code"
                [password]="source.billing_password"
                [showPasswordStrength]="false"
                [label]="'BILLING_CREDENTIALS' | translate"
                [userLabel]="'COPY_CODE' | translate"
                [canEdit]="canEdit"
            ></app-copy-credentials>
            <app-copy-credentials
                *ngIf="source.autopull_billing_code"
                [user]="source.autopull_billing_code"
                [password]="source.autopull_billing_password"
                [showPasswordStrength]="false"
                [label]="'AUTOPULL_BILLING_CREDENTIALS' | translate"
                [userLabel]="'COPY_CODE' | translate"
                [canEdit]="canEdit"
            ></app-copy-credentials>
            <app-generic-detail header="TRAFFIC_SHAPING" [content]="source.traffic_shaping_kbps ?? ''" unit="kbps"></app-generic-detail>
            <app-generic-detail header="MAX_FEC_OVERHEAD" [content]="source.max_fec_overhead_percent ?? ''" unit="%"></app-generic-detail>
            <app-generic-detail header="FRAME_THINNING_LATENCY" [content]="source.frame_thinning_latency ?? ''" unit="ms"></app-generic-detail>
            <app-generic-detail header="MTU" [content]="source.mtu ?? ''"></app-generic-detail>
            <app-generic-detail header="AUTO_PULL_MTU" [content]="source.autopull_mtu ?? ''"></app-generic-detail>
            <div *ngIf="source.type === 'transcoded'">
                <app-generic-detail header="ENCODER" [content]="source.transcode_encoder ? encoderDisplayName : ''"></app-generic-detail>
                <app-generic-detail
                    header="THREADS"
                    [content]="source.transcode_threads && source.transcode_encoder === 'x264' ? source.transcode_threads : ''"
                ></app-generic-detail>
            </div>
            <app-generic-detail header="BIND_INPUT_NIC_SELECTION" [content]="source.bind_input_device ? ('ENABLED' | translate) : ''"></app-generic-detail>
            <app-generic-detail
                header="FROZEN_VIDEO_MINIMAL_DURATION"
                [content]="source.freeze_detection_timeout_sec ?? ''"
                unit="seconds"
            ></app-generic-detail>
            <app-generic-detail header="BLANK_VIDEO_MINIMAL_DURATION" [content]="source.blank_detection_timeout_sec ?? ''" unit="seconds"></app-generic-detail>
            <div *ngIf="source.type === 'udp' || source.type === 'rtp'">
                <app-generic-detail header="DEJITTER_BUFFER" [content]="source.dejitter_buffer ?? ''"></app-generic-detail>
                <app-generic-detail
                    header="DEJITTER_CLOCK"
                    [content]="source.dejitter_clock === 0 ? ('ARRIVAL_TIMES' | translate) : ('RTP_TIMESTAMP' | translate)"
                ></app-generic-detail>
            </div>

            <div *ngIf="source.type === 'transcoded'">
                <app-generic-detail header="FORCE_I_FRAME_ON_SCTE" [content]="source.force_i_frame_on_scte ? ('ENABLED' | translate) : ''"></app-generic-detail>
                <app-generic-detail header="HTML_OVERLAY_URL" [content]="source.html_overlay_url ?? ''"></app-generic-detail>
                <app-generic-detail header="TRANSCODE_TSID" [content]="source.transcode_tsid ?? ''"></app-generic-detail>
            </div>
        </div>
    </div>
</div>
