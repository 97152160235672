<div>
    <button
        class="btn border-0 btn-outline-secondary btn-ellipsis"
        id="actionDropdown"
        [matMenuTriggerFor]="headerMenu"
        container="body"
        ngbTooltip="{{ 'MORE_ACTIONS' | translate }}"
        placement="top-right"
        triggers="hover"
    >
        <fa-icon icon="ellipsis-v" size="1x"></fa-icon>
    </button>

    <div aria-labelledby="actionDropdown" class="shadow-sm">
        <mat-menu #headerMenu="matMenu" yPosition="below">
            <!-- Mobile Mute -->
            <button
                class="d-inline-block d-md-none text-secondary"
                title="{{ 'MUTE' | translate }}"
                (click)="toggleMute.emit()"
                [disabled]="!canEdit"
                mat-menu-item
            >
                <fa-icon [icon]="muted ? 'bell' : 'bell-slash'" size="sm" [fixedWidth]="true"></fa-icon>
                {{ (muted ? "UNMUTE" : "MUTE") | translate }}
            </button>
            <!-- Mute Until -->
            <button
                (click)="openDatePicker($event)"
                class="text-secondary"
                [disabled]="!canEdit"
                title="{{ 'MUTE_UNTIL_A_SPECIFIC' | translate }}"
                mat-menu-item
            >
                <fa-icon icon="clock" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "MUTE_UNTIL" | translate }}...
            </button>
            <!-- Mobile Enable/Disable -->
            <button
                *ngIf="canToggle"
                [disabled]="!canEdit || !canEnable"
                class="d-inline-block d-md-none"
                [attr.title]="(isEnabled ? 'DISABLE' : 'ENABLE') | translate"
                [ngClass]="isEnabled ? 'text-warning' : 'text-success'"
                (click)="toggle.emit()"
                mat-menu-item
            >
                <fa-icon [icon]="isEnabled ? 'ban' : 'power-off'" size="sm" [fixedWidth]="true"></fa-icon>
                {{ (isEnabled ? "DISABLE" : "ENABLE") | translate }}
            </button>
            <!-- Add -->
            <button *ngIf="add" [attr.title]="add.text" [disabled]="!canEdit" class="text-primary" (click)="addLink()" mat-menu-item>
                <fa-icon icon="plus" size="sm" [fixedWidth]="true"></fa-icon>
                {{ add.text }}
            </button>
            <!-- Open -->
            <button
                *ngIf="linkUrl"
                [attr.title]="'OPEN' | translate"
                [disabled]="!(state !== 'pending' && canEdit)"
                class="text-primary"
                (click)="openLink()"
                mat-menu-item
            >
                <fa-icon icon="external-link-alt" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "OPEN" | translate }}
            </button>
            <!-- Mobile Edit -->
            <button [disabled]="!canEdit" class="d-inline-block d-md-none text-primary" [attr.title]="'EDIT' | translate" (click)="edit()" mat-menu-item>
                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "EDIT" | translate }}
            </button>
            <!-- Clone -->
            <button *ngIf="cloneBtn" (click)="clone.emit()" [disabled]="userPermissions" class="text-primary" mat-menu-item>
                <fa-icon [icon]="['far', 'clone']" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "CLONE" | translate }}
            </button>
            <!-- Maintenance -->
            <button
                *ngIf="maintenanceBtn"
                title="{{ isMaintenance ? ('CLEAR_MAINTENANCE' | translate) : ('SET_MAINTENANCE' | translate) }}"
                (click)="toggleMaintenance.emit()"
                class="text-secondary"
                mat-menu-item
            >
                <fa-icon icon="wrench" size="1x" [fixedWidth]="true"></fa-icon>
                {{ isMaintenance ? ("CLEAR_MAINTENANCE" | translate) : ("SET_MAINTENANCE" | translate) }}
            </button>
            <!-- Switch -->
            <button *ngIf="switchText" [attr.title]="switchText" [disabled]="!canSwitch" class="text-secondary" (click)="switch.emit()" mat-menu-item>
                <fa-icon icon="random" size="sm" [fixedWidth]="true"></fa-icon>
                {{ switchText }}
            </button>
            <!-- Configure -->
            <button *ngIf="configBtn" (click)="config.emit()" class="text-secondary" mat-menu-item>
                <fa-icon icon="gear" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "CONFIG" | translate }}
            </button>
            <!-- Share -->
            <button *ngIf="shareBtn" (click)="share.emit()" [disabled]="!canEdit" class="text-secondary" mat-menu-item>
                <fa-icon icon="share" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "SHARE" | translate }}
            </button>
            <!-- Traceroute -->
            <button *ngIf="tracerouteBtn" (click)="traceroute.emit()" class="text-secondary" mat-menu-item>
                <fa-icon icon="list-ol" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "TRACEROUTE" | translate }}
            </button>
            <!-- GO TO RS-->
            <button
                *ngIf="canEdit && (altActionBtn === recoveryState.primary || altActionBtn === recoveryState.alternative)"
                class="text-secondary"
                (click)="altAction.emit()"
                title="{{ 'GO_TO' | translate }} {{ altActionBtn === recoveryState.alternative ? ('PRIMARY' | translate) : ('ALTERNATIVE' | translate) }}"
                mat-menu-item
            >
                <fa-icon icon="alt" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "GO_TO" | translate }} {{ altActionBtn === recoveryState.alternative ? ("PRIMARY" | translate) : ("ALTERNATIVE" | translate) }}
            </button>
            <!-- Delete-->
            <button
                (click)="delete.emit()"
                [disabled]="!(canEdit && !terminationProtection)"
                class="text-danger"
                [attr.title]="terminationProtection ? ('TERMINATION_PROTECTION_DELETE_WARNING' | translate) : ('DELETE' | translate)"
                mat-menu-item
            >
                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "DELETE" | translate }}
            </button>
        </mat-menu>
    </div>
</div>

<!-- Picker -->
<div class="picker-container fixed" *ngIf="showPicker">
    <div class="arrow"></div>
    <div class="picker-content shadow-sm text-end muteUntil">
        <fa-icon icon="times-circle" class="close-icon" (click)="close()"></fa-icon>
        <zx-date-time-picker name="muteDate" [(ngModel)]="muteUntilString" (ngModelChange)="dateChanged($event)" [onlyFutureDates]="true"></zx-date-time-picker>
        <button type="button" class="btn btn-outline-secondary btn-sm mt-2 mb-2" (click)="close()">
            <fa-icon icon="times" size="xs"></fa-icon>
            {{ "CANCEL" | translate }}
        </button>
        <button type="button" class="btn btn-primary btn-sm mt-2 ms-2 me-2 mb-2" [disabled]="!muteUntilVal" (click)="save(muteUntilVal)">
            <fa-icon icon="bell-slash" size="xs"></fa-icon>
            {{ "MUTE" | translate }}
        </button>
    </div>
</div>
