<div class="page-title" id="pageTitle" #pageTitle>
    <div class="title-section">
        <h2 class="ellipsis">
            <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                <fa-icon icon="times" size="1x" class="d-none d-lg-block"></fa-icon>
                <fa-icon icon="chevron-left" size="1x" class="d-lg-none"></fa-icon>
            </button>
            <ng-content select="[title]"></ng-content>
        </h2>
    </div>
    <!-- Primary Actions -->
    <div class="primary-action-section">
        <ng-content select="[action-buttons]"></ng-content>
        <ng-content select="[drop-down]"></ng-content>
    </div>
</div>
