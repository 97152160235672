import { AfterViewInit, Component, ElementRef, EventEmitter, NgZone, Output, ViewChild } from "@angular/core";
import * as d3 from "d3";

@Component({
    selector: "app-chart-container",
    templateUrl: "./chart-container.component.html"
})
export class ChartContainerComponent implements AfterViewInit {
    @ViewChild("chartContainer", { read: ElementRef }) chartContainer: ElementRef<HTMLDivElement>;
    @Output() runOutsideAngularHandler = new EventEmitter<d3.Selection<HTMLDivElement, undefined, null, undefined>>();

    constructor(private ngZone: NgZone) {}

    ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            const chartContainerD3Selection = d3.select(this.chartContainer.nativeElement) as d3.Selection<
                HTMLDivElement,
                undefined,
                null,
                undefined
            >;
            this.runOutsideAngularHandler.emit(chartContainerD3Selection);
        });
    }
}
