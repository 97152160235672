<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "CONFIGURATION_HELP.TITLE" | translate }}&nbsp;-&nbsp;{{ source.name }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="details-form">
            <h4 *ngIf="!source.protocol" translate>CONFIGURE_ZIXI_ENABLED_ENCODER_WITH_FOLLOWING_PARAMS</h4>
            <h4
                *ngIf="
                    ['udp', 'rtp', 'rist', 'rist_push'].includes(source.type) ||
                    (source.type === 'srt' && source.srt_mode === 'push') ||
                    (source.type === 'rtmp' && !source.pull_mode)
                "
                translate
            >
                CONFIGURE_YOUR_ENCODER_WITH_FOLLOWING_PARAMETERS
            </h4>
            <h4
                *ngIf="
                    ['rist_pull', 'hls_pull'].includes(source.type) ||
                    (source.type === 'srt' && source.srt_mode === 'pull') ||
                    (source.type === 'rtmp' && source.pull_mode)
                "
                translate
            >
                SETUP_ACCESS_FOR_CONNECTION_ON_THE_FOLLOWING_ENDPOINT
            </h4>
            <h4 *ngIf="source.protocol === 'zixi_pull'" translate>SETUP_ACCESS_FOR_ZIXI_CONNECTION_ON_THE_FOLLOWING_ENDPOINT</h4>
            <div
                *ngIf="
                    !source.protocol ||
                    ['udp', 'rtp', 'rist', 'rist_push'].includes(source.type) ||
                    (source.type === 'srt' && source.srt_mode === 'push') ||
                    (source.type === 'rtmp' && !source.pull_mode)
                "
                class="form-group row"
            >
                <h5 class="col-12" translate>HOST</h5>
                <div class="col-12">
                    <span
                        >{{ source.target_host }}
                        <button class="btn btn-sm btn-xs btn-copy" (click)="copy(source.target_host)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon></button
                    ></span>
                </div>
            </div>
            <div *ngIf="['rist_pull', 'zixi_pull'].indexOf(source.type) >= 0 || (source.type === 'srt' && source.srt_mode === 'pull')" class="form-group row">
                <h5 class="col-12" translate>HOST</h5>
                <div class="col-12">
                    <span
                        >{{ source.remote_host
                        }}<button class="btn btn-sm btn-xs btn-copy" (click)="copy(source.remote_host)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon></button
                    ></span>
                </div>
            </div>

            <div
                *ngIf="['rist_push', 'rist', 'udp', 'rtp'].indexOf(source.type) >= 0 && source.multicast_address && source.multicast_address !== ''"
                class="form-group row"
            >
                <h5 class="col-12" translate>MULTICAST_ADDRESS</h5>
                <div class="col-12">
                    <span>
                        {{ source.multicast_address }}
                        <button class="btn btn-sm btn-xs btn-copy" (click)="copy(source.multicast_address)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon>
                        </button>
                    </span>
                </div>
            </div>

            <div
                *ngIf="['rist_push', 'rist', 'udp', 'rtp'].indexOf(source.type) >= 0 && source.multicast_source && source.multicast_source !== ''"
                class="form-group row"
            >
                <h5 class="col-12" translate>MULTICAST_SOURCE</h5>
                <div class="col-12">
                    <span>
                        {{ source.multicast_source }}
                        <button class="btn btn-sm btn-xs btn-copy" (click)="copy(source.multicast_source)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon></button
                    ></span>
                </div>
            </div>

            <div
                *ngIf="
                    !source.protocol ||
                    ['udp', 'rtp', 'rist', 'rist_push'].includes(source.type) ||
                    (source.type === 'srt' && source.srt_mode === 'push') ||
                    (source.type === 'rtmp' && !source.pull_mode)
                "
                class="form-group row"
            >
                <h5 class="col-12" translate>PORT</h5>
                <div class="col-12">
                    <span *ngIf="!source.protocol"
                        >{{ "ONE_OF" | translate }} {{ clusterPorts().join(", ") }}
                        <button class="btn btn-sm btn-xs btn-copy" (click)="copy(pickClusterPort())" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon>
                        </button>
                    </span>
                    <span *ngIf="['udp', 'rtp', 'srt', 'rist', 'rist_push'].indexOf(source.type) >= 0"
                        >{{ source.listening_port }}
                        <button class="btn btn-sm btn-xs btn-copy" (click)="copy(source.listening_port)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon>
                        </button>
                    </span>
                    <span *ngIf="source.type === 'rtmp' && !source.pull_mode && source.inputCluster.rtmp_input_port"
                        >{{ source.inputCluster.rtmp_input_port }}
                        <button
                            class="btn btn-sm btn-xs btn-copy"
                            (click)="copy(source.inputCluster.rtmp_input_port)"
                            type="button"
                            title="{{ 'COPY' | translate }}"
                        >
                            <fa-icon icon="clipboard" size="1x"></fa-icon>
                        </button>
                    </span>
                    <span *ngIf="source.type === 'rtmp' && !source.pull_mode && !source.inputCluster.rtmp_input_port"
                        >{{ "ENABLE_RTMP_SERVER_ON_CLUSTER" | translate }}
                    </span>
                </div>
            </div>
            <div *ngIf="['rist_pull', 'zixi_pull'].indexOf(source.type) >= 0 || (source.type === 'srt' && source.srt_mode === 'pull')" class="form-group row">
                <h5 class="col-12" translate>PORT</h5>
                <div class="col-12">
                    <span *ngIf="source.remote_port"
                        >{{ source.remote_port
                        }}<button class="btn btn-sm btn-xs btn-copy" (click)="copy(source.remote_port)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon></button
                    ></span>
                    <span *ngIf="!source.remote_port && source.type === 'zixi_pull'"
                        >2088<button class="btn btn-sm btn-xs btn-copy" (click)="copy(2088)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon></button
                    ></span>
                </div>
            </div>

            <div
                *ngIf="
                    !source.protocol ||
                    source.protocol === 'zixi_pull' ||
                    (source.type === 'rtmp' && !source.pull_mode) ||
                    (source.type === 'srt' && source.srt_mode === 'push' && source.verify_srt_stream_id)
                "
                class="form-group row"
            >
                <h5 class="col-12" translate>STREAM_ID</h5>
                <div class="col-12">
                    <span
                        >{{ source.stream_id
                        }}<button class="btn btn-sm btn-xs btn-copy" (click)="copy(source.stream_id)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon></button
                    ></span>
                </div>
            </div>

            <div *ngIf="(source.type === 'rtmp' && source.pull_mode) || source.type === 'hls_pull'" class="form-group row">
                <h5 class="col-12" translate>URL</h5>
                <div class="col-12">
                    <span
                        >{{ source.url
                        }}<button class="btn btn-sm btn-xs btn-copy" (click)="copy(source.url)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon></button
                    ></span>
                </div>
            </div>

            <div *ngIf="source.type === 'rtmp' && source.pull_mode" class="form-group row">
                <h5 class="col-12" translate>STREAM</h5>
                <div class="col-12">
                    <span
                        >{{ source.input_id
                        }}<button class="btn btn-sm btn-xs btn-copy" (click)="copy(source.input_id)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon></button
                    ></span>
                </div>
            </div>

            <div *ngIf="source.type === 'rtmp' && source.username && source.password" class="form-group row">
                <h5 class="col-12" translate>USERNAME</h5>
                <div class="col-12">
                    <span
                        >{{ source.username
                        }}<button class="btn btn-sm btn-xs btn-copy" (click)="copy(source.username)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="1x"></fa-icon></button
                    ></span>
                </div>
            </div>

            <div *ngIf="['udp', 'rtp', 'rist'].indexOf(source.protocol) === -1 && source.password" class="form-group row">
                <h5 class="col-12" translate>PASSWORD</h5>
                <div class="col-12">
                    <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">
                        {{ "SHOW" | translate }}
                    </a>
                    <span *ngIf="pwVisible">{{ source.password }}</span> /
                    <a href="javascript:void(0)" (click)="copy(source.password)">
                        {{ "COPY" | translate }}
                    </a>
                </div>
            </div>

            <div *ngIf="source.decryption_key != null && source.decryption_key !== ''" class="form-group row">
                <h5 class="col-12" translate>DECRYPTION_KEY</h5>
                <div class="col-12">
                    <a href="javascript:void(0)" (click)="keyVisible = true" *ngIf="!keyVisible">
                        {{ "SHOW" | translate }}
                    </a>
                    <span *ngIf="keyVisible">{{ source.decryption_key }}</span> /
                    <a href="javascript:void(0)" (click)="copy(source.decryption_key)">
                        {{ "COPY" | translate }}
                    </a>
                </div>
            </div>
            <div *ngIf="source.type === 'multiview'" class="form-group row">
                <content-loading-animation *ngIf="(source._frontData.multiviewConfig?.length ?? 0) === 0"></content-loading-animation>
                <textarea
                    *ngIf="source._frontData.multiviewConfig"
                    id="multiviewConfig"
                    name="multiviewConfig"
                    class="form-control"
                    [(ngModel)]="source._frontData.multiviewConfig"
                    rows="20"
                    disabled
                ></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()" ngbAutofocus>
            {{ "CLOSE" | translate }}
        </button>
    </div>
</div>
