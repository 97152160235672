import { Component, Input, OnInit, Output, EventEmitter, inject } from "@angular/core";
import { BehaviorSubject, firstValueFrom, map } from "rxjs";
import moment from "moment";
import _, { isEmpty } from "lodash";

import { SharedService } from "src/app/services/shared.service";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { EventsService } from "src/app/pages/events/events.service";

import { Tag } from "src/app/models/shared";
import { Constants } from "src/app/constants/constants";
import { QueryParamsService } from "src/app/services/query-params.service";
import { FormBuilder } from "@angular/forms";
import { TimeZoneService } from "src/app/services/time-zone.service";

export interface EventObjectType {
    name: string;
    type?: string[];
}

export interface TypeFilter {
    text: "Error" | "Warning" | "Info" | "Ok";
    color: "danger" | "warning" | "info" | "success" | "primary";
    key: "error" | "warning" | "info" | "success";
    enabled: boolean;
}

export interface EventFilter {
    fromDate: moment.Moment;
    toDate: moment.Moment;
    msgFilter: string;
    msgTypes: { [key in TypeFilter["key"]]: boolean };
    objectType: EventObjectType;
    resourceTags: number[];
    startsIn?: number;
}

export type EventsColumnsSelection = {
    date_time: boolean;
    message: boolean;
    rule: boolean;
};

@Component({
    selector: "app-events-filter-form",
    templateUrl: "./events-filter-form.component.html"
})
export class EventsFilterFormComponent implements OnInit {
    @Input() filterName: string;
    @Input() saveFilter = false;
    @Input() downloadEvents = false;
    @Input() responsive = false;
    @Input() columns: EventsColumnsSelection;
    @Input() objectTypes: EventObjectType[] = [];
    private resourceTags: Tag[] = [];
    @Input() typeFilters: TypeFilter[] = [
        { text: "Error", color: "danger", key: "error", enabled: true },
        { text: "Warning", color: "warning", key: "warning", enabled: true },
        { text: "Info", color: "info", key: "info", enabled: true },
        { text: "Ok", color: "success", key: "success", enabled: true }
    ];
    @Input() showColumnSelection = true;
    @Input() hasEvents = true;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onApply: EventEmitter<EventFilter> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onReset: EventEmitter<void> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onToggleColumn: EventEmitter<{ key: string; value: boolean }> = new EventEmitter();

    constants = Constants;
    showFilter = false;
    onlyPastDates = true;
    onlyFutureDates = false;

    private es = inject(EventsService);
    private queryParamsService = inject(QueryParamsService);
    private userService = inject(UsersService);
    private formBuilder = inject(FormBuilder);
    private timeZoneService = inject(TimeZoneService);
    sharedService = inject(SharedService);
    userPermissions$ = this.userService.userPermissions.pipe(
        map(
            userPermissions =>
                this.downloadEvents &&
                (userPermissions.is_zixi_support_write ||
                    userPermissions.is_zixi_admin ||
                    userPermissions.is_admin ||
                    userPermissions.is_objects_manager)
        )
    );
    private formInitialValue = {};
    form = this.formBuilder.group({
        msgFilter: [""],
        fromDate: [""],
        toDate: [""],
        objectType: [{ name: "ANY" } as EventObjectType],
        resourceTags: [[] as Tag[]],
        msgTypes: [{} as { [key in TypeFilter["key"]]: boolean }]
    });

    canDownloadEvents$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    canDownloadEvents() {
        const { fromDate, toDate, objectType } = this.form.value;
        const hasAllData = !!(fromDate && toDate && objectType?.type);
        if (!hasAllData) return false;
        return this.form.pristine;
    }

    prepForm(params: Partial<EventFilter>) {
        const resourceTagIds = params.resourceTags || [];

        this.form.patchValue({
            msgFilter: params.msgFilter || "",
            fromDate: params.fromDate ? moment(params.fromDate).toISOString() : null,
            toDate: params.toDate ? moment(params.toDate).toISOString() : null,
            objectType: this.objectTypes.find(objectType => objectType.name === params.objectType?.name) || {
                name: "ANY"
            },
            resourceTags: this.resourceTags.filter(tag => resourceTagIds.includes(tag.id)),
            msgTypes: params.msgTypes
        });

        if (params.msgTypes) this.typeFilters.forEach(type => (type.enabled = !!params.msgTypes[type.key]));
    }

    async ngOnInit() {
        this.resourceTags = await firstValueFrom(this.sharedService.getResourceTagsByType());
        this.formInitialValue = this.form.value;
        const queryParams = await firstValueFrom(this.queryParamsService.eventsParams$);

        if (!isEmpty(queryParams)) {
            this.prepForm(queryParams);
            this.apply();
        } else if (this.saveFilter) {
            // Check localStorage
            if (this.saveFilter) {
                const ls = localStorage.getItem(`${this.filterName}.filter`);
                if (ls) {
                    const filter: Partial<EventFilter> = JSON.parse(ls);
                    this.prepForm(filter);
                    this.apply();
                }
            }
        } else {
            this.prepForm({});
            this.apply();
        }

        this.form.valueChanges.subscribe(() => this.onFilterChange());
    }

    apply() {
        const timeZone = this.timeZoneService.selectedTimeZone();
        this.form.markAsPristine(); //Did it to allow download events button to be enabled
        const eventsFilter: EventFilter = {
            ...this.form.getRawValue(),
            resourceTags: this.form.value?.resourceTags?.map(tag => tag.id),
            msgFilter: this.form.value.msgFilter || "",
            fromDate: this.form.value.fromDate
                ? moment.tz(
                      this.form.value.fromDate,
                      timeZone?.utc[0] ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                  )
                : null,
            toDate: this.form.value.toDate
                ? moment.tz(
                      this.form.value.toDate,
                      timeZone?.utc[0] ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                  )
                : null,
            msgTypes: this.getMsgTypes()
        };

        this.queryParamsService.updateEventParams(eventsFilter);
        //
        if (this.saveFilter) localStorage.setItem(`${this.filterName}.filter`, JSON.stringify(eventsFilter));
        this.canDownloadEvents$.next(this.canDownloadEvents());
        this.onApply.emit(eventsFilter);
    }

    reset() {
        this.typeFilters.forEach(type => (type.enabled = true));
        this.form.reset(this.formInitialValue);
        localStorage.removeItem(`${this.filterName}.filter`);
        this.canDownloadEvents$.next(this.canDownloadEvents());
        this.onReset.emit();
    }

    toggleTypeFilter(type: TypeFilter) {
        type.enabled = !type.enabled;
        this.form.controls.msgTypes.setValue(this.getMsgTypes());
    }

    private getMsgTypes() {
        return this.typeFilters.reduce((acc, type) => {
            acc[type.key] = !!type.enabled;
            return acc;
        }, {} as EventFilter["msgTypes"]);
    }

    onFilterChange() {
        if (this.responsive) setTimeout(() => this.apply(), 0);
    }

    toggleColumn(column) {
        this.onToggleColumn.emit(column);
    }

    getReportParameters() {
        const timeZone = this.timeZoneService.selectedTimeZone();
        const formValue = this.form.value;
        const eventsReportParams = {
            fromDate: this.form.value.fromDate
                ? moment.tz(
                      this.form.value.fromDate,
                      timeZone?.utc[0] ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                  )
                : null,
            toDate: this.form.value.toDate
                ? moment.tz(
                      this.form.value.toDate,
                      timeZone?.utc[0] ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                  )
                : null,
            msgFilter: formValue.msgFilter,
            objectType: formValue.objectType
        };

        return this.es.getEventsParameters(eventsReportParams, true).toString();
    }
}
