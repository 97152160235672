<div class="dynamic-controls" [ngClass]="{ isTab: !isMultiSelect }">
    <!-- Menu -->
    <div class="menu-controls">
        <ul class="flex-menu" [ngClass]="{ tabs: !isMultiSelect }">
            <li
                *ngFor="let widgetHeader of nonHiddenWidgets"
                class="flex-menu-li"
                [ngClass]="{ selected: widgetHeader.isSelected, disabled: isLocked && isMultiSelect }"
                (click)="onWidgetMenuClick(widgetHeader.title)"
                title="{{ widgetHeader.title }}"
            >
                <fa-icon [icon]="widgetHeader.fa_icon" size="sm" [title]="widgetHeader.title"></fa-icon>
                <span class="ms-1 d-none d-sm-inline-block">{{ widgetHeader.title }}</span>
            </li>
        </ul>
    </div>
    <!-- Column Controls -->
    <div class="column-controls form-inline" *ngIf="!isMobile">
        <label for="mode" class="sr-only">{{ "MODE" | translate }}</label>
        <mat-button-toggle-group class="xs secondary flex-nowrap" id="mode" name="mode" aria-label="mode" [(ngModel)]="isMultiSelect">
            <mat-button-toggle
                (click)="toggleMultiSelect(false)"
                class="secondary"
                [value]="false"
                container="body"
                ngbTooltip="{{ 'TABS' | translate }}"
                triggers="hover"
                placement="top"
                tourAnchor="seventhCustomLayoutAnchor"
            >
                <fa-icon icon="square" size="1x"></fa-icon>
                <span class="sr-only" translate>TABS</span>
            </mat-button-toggle>
            <mat-button-toggle
                (click)="toggleMultiSelect(true)"
                class="secondary"
                [value]="true"
                container="body"
                ngbTooltip="{{ 'WIDGETS' | translate }}"
                triggers="hover"
                placement="top"
                tourAnchor="eigthCustomLayoutAnchor"
            >
                <fa-icon icon="grid-2" size="1x"></fa-icon>
                <span class="sr-only" translate>WIDGETS</span>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <!-- save & reset buttons -->
    <ng-content></ng-content>
    <!-- pin buttons -->
    <div class="pin-button-section" *ngIf="!isMobile">
        <app-pin-button [isLocked]="isLocked" [isPinned]="isSectionPinned" (isPinnedChange)="isSectionPinnedChange.emit($event)"></app-pin-button>
    </div>
</div>
