<div [ngClass]="{ loading: saving }">
    <div class="modal-header">
        <h3 class="modal-title">{{ "OPEN_INCIDENT" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()">
        <div class="modal-body">
            <!-- New/Existing -->
            <fieldset class="form-group" *ngIf="state !== 'done'">
                <legend for="type">{{ "ADD_TO" | translate }}</legend>
                <mat-button-toggle-group name="type" aria-label="type" [(ngModel)]="newIncident">
                    <mat-button-toggle [value]="true">{{ "NEW" | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="false">{{ "EXISTING" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </fieldset>
            <!-- Incident Select -->
            <div class="form-group mb-0" *ngIf="state !== 'done' && !newIncident">
                <label for="id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.id?.errors }"
                    >{{ "INCIDENT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                ></label>
                <zx-incident-select
                    name="id"
                    [(model)]="incidentID"
                    [required]="true"
                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.id?.errors }"
                ></zx-incident-select>
                <div *ngIf="form.controls.id?.invalid" class="invalid-feedback">
                    <div *ngIf="form.controls.id?.errors.required">{{ "INCIDENT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                </div>
            </div>
            <!-- Incident Name -->
            <div class="form-group" [ngClass]="{ 'mb-0': name.errors }" *ngIf="state !== 'done' && newIncident">
                <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                    >{{ "INCIDENT_NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                ></label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="{{ 'NAME' | translate }}"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                    [(ngModel)]="incidentName"
                    required
                    minlength="{{ minLength }}"
                    pattern="{{ constants.validators.name }}"
                    #name="ngModel"
                />
                <div *ngIf="name.invalid" class="invalid-feedback">
                    <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                    <div *ngIf="name.errors.minlength">
                        {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                    </div>
                    <div *ngIf="name.errors.pattern">{{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `</div>
                </div>
            </div>
            <!-- Incident Manual Likely Cause -->
            <div class="form-group" *ngIf="!event && state !== 'done' && newIncident">
                <label for="likely_cause" [ngClass]="{ 'is-invalid': form.submitted && likely_cause.errors }"> {{ "ROOT_CAUSE" | translate }}</label>
                <ng-select
                    id="likely_cause"
                    name="likely_cause"
                    required
                    [items]="possibleCauses"
                    [clearable]="false"
                    placeholder="{{ 'SELECT' | translate }} {{ 'ROOT_CAUSE' | translate }}"
                    [(ngModel)]="likelyCause"
                    #likely_cause="ngModel"
                    [ngClass]="{ 'form-control is-invalid': form.submitted && likely_cause.errors }"
                >
                </ng-select>
                <div *ngIf="likely_cause.invalid" class="invalid-feedback">
                    <div *ngIf="likely_cause.errors.required">{{ "ROOT_CAUSE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                </div>
            </div>
            <!-- From/To -->
            <div class="form-group" *ngIf="!event && state !== 'done' && newIncident && from">
                <label>{{ "START" | translate }} {{ "TIME" | translate }}</label>
                <br />
                <span>{{ from.format("LLLL") }}</span>
            </div>
            <div class="form-group" *ngIf="!event && state !== 'done' && newIncident && to">
                <label>{{ "END" | translate }} {{ "TIME" | translate }}</label>
                <br />
                <span>{{ to.format("LLLL") }}</span>
            </div>
            <!-- Result -->
            <div class="form-group mb-0" *ngIf="state === 'done'">
                <label for="new_incident" *ngIf="newIncident"> {{ "NEW_INCIDENT_CREATED" | translate }}: </label>
                <label for="exsiting_incident" *ngIf="!newIncident"> {{ "EXISTING_INCIDENT_UPDATED" | translate }}: </label>
                <div>
                    <!-- TODO: handle error -->
                    <a href="/incidents/{{ incident.id }}/">
                        {{ incident.name }}
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" [disabled]="saving" (click)="activeModal.close()" *ngIf="state !== 'done'">
                <fa-icon icon="times"></fa-icon>
                {{ "CANCEL" | translate }}
            </button>
            <button type="button" class="btn btn-primary" ngbAutofocus [disabled]="saving" zmid="form-submit" type="submit" *ngIf="state !== 'done'">
                <fa-icon icon="check" size="sm"></fa-icon>
                {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
            </button>
            <button type="button" class="btn btn-secondary" [disabled]="saving" (click)="activeModal.close()" *ngIf="state === 'done'">
                {{ "CLOSE" | translate }}
            </button>
        </div>
    </form>
</div>
